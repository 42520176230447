import {defineStore} from 'pinia'
import menuItems from "@/const/menuItems";
import layout from "@/const/layout";


export const useControlsState = defineStore('controls', {
    state: () => {
        return {
            activeMenu: menuItems.SEARCH,
            layout: layout.NORMAL,
            wayfindingActive: false,
            showLocationActive: false,
        }
    }
})
