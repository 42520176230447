<template>
  <div class="col-12 white-bg search-place-2 d-flex flex-column px-5">
    <div class="row search-top">
      <div class="col-12">
        <div class="row">
          <div class="col-2">
            <img v-if="activeTenant.image" :src="activeTenant.image"/>
            <p v-else style="font-size: 3rem;" v-html="activeTenant.name"></p>
          </div>
          <div class="col name-level">
            <span class="name" v-html="activeTenant.name"></span>
            <span class="level"
                  v-if="activeTenant.floors!== undefined && activeTenant.floors.length > 0">{{ translation.floor }} {{
                activeTenant.floors.join(', ')
              }}</span>
          </div>
          <div class="col-auto button go-to-animation" v-if="activeTenant.locations.length > 0"
               @click="wayfindingTenant=activeTenant; activeTenant = null; wayfindingActive=true">
            <span class="action">{{ translation.goTo }}</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row" style="font-size: 2rem">
          <div class="col">
            <template v-for="category, num in getTenantCategories(activeTenant)" :key="category">
              <template v-if="num !== 0">&nbsp;|&nbsp;</template>
              {{ category }}
            </template>
          </div>
        </div>
      </div>
    </div>


    <div class="row search-middle scroll">
      <div class="col">
                    <span class="info" v-html="activeTenant.description ">
                    </span>
      </div>
    </div>
    <div class="row search-bottom">
      <div class="col">
        <span class="text-left">
                      <template v-if="activeTenant.workingHours">
                        <template v-for="hours, key in activeTenant.workingHours" :key="key">
                          <template v-if="key!=='today'">
                          <p>{{ key }}: {{ hours }}</p>
                          </template>
                        </template>
                      </template>

        </span>
      </div>
      <div class="col name-level">
        <span class="text-middle" v-if="activeTenant.phone">Telefon:	{{ activeTenant.phone }}</span>
      </div>
      <div class="col-auto button-alt" @click="activeTenant=null">
        <span class="action">{{ translation.back }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import {mapWritableState} from "pinia/dist/pinia";
import {useTenantsStore} from "@/stores/tenantsStore";
import {useConfigState} from "@/stores/configStore";
import {useControlsState} from "@/stores/controlsStore";

export default {
  name: "TenantResult",
  computed: {
    ...mapWritableState(useTenantsStore, ['activeTenant', 'wayfindingTenant']),
    ...mapWritableState(useControlsState, ['wayfindingActive']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        back: configStore.getTranslation('back', 'tenant'),
        floor: configStore.getTranslation('floor', 'tenant'),
        goTo: configStore.getTranslation('goTo', 'tenant'),
      }
    }
  }, methods: {

    getCategoryById: function (categoryId) {
      const store = new useTenantsStore()

      let category = null
      for (const cat of store.categories) {
        if (cat.id == categoryId) {
          category = cat.name
        }
      }
      return category

    },
    getTenantCategories: function (tenant) {

      let categories = []
      if (tenant.categories !== null && tenant.categories !== undefined) {
        for (const categoryId of tenant.categories) {

          const category = this.getCategoryById(categoryId)

          if (category !== null) {
            categories.push(category)
          }
        }
      }

      return categories
    }

  }
  }
</script>

<style scoped>
.search-middle {
  overflow-y: auto !important;
}


</style>
