<template>
  <div id="loading-screen">
    <span>Loading</span>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen"
}
</script>

<style scoped>
#loading-screen {
  width: 100vw;
  height: 100vh;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#loading-screen span {
  font-size: 5em;
}
</style>
