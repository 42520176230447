<template>
  <section class="container wygaszacz-space" id="screensaver-gap-1-area">
  </section>

  <section class="container position-relative"
           id="screensaver-controls-area">
    <div class="row">
      <div :class="{'col-6':isAlternativeState, 'col-10':!isAlternativeState}">
        <a href="#" class="wygaszacz-button-left" @click.prevent="$emit('startClick')">
            <span>
              {{ translation.browse }}
            </span>
        </a>
      </div>
      <div class="col-2 flex-end">
        <a href="#" class="wygaszacz-button-right" @click.prevent="$emit('startClick')">
          <img src="@/assets/img/wygaszacz-arrow.svg">
        </a>
      </div>
      <div class="col-4 flex-end" v-if="isAlternativeState">
        <a href="#" class="wygaszacz-button-right change-layout" @click.prevent="changeLayout">
          <span class="ps-5">{{ translation.changeLayout }}</span>
          <WheelchairIcon></WheelchairIcon>
        </a>
      </div>
    </div>
  </section>

  <section class="container wygaszacz-space-2" id="screensaver-gap-2-area">
  </section>

  <section class="container position-relative" id="screensaver-tenants-1-area">
    <div class="row d-flex justify-content-between">
      <div class="col-auto wygaszacz-box active-box position-relative" @click.prevent="$emit('startClick')">
        <img class="custom1" src="@/assets/img/wygaszacz-shop.svg">
        <span>{{ translation.stores }}</span>
      </div>
      <div class="col-auto wygaszacz-box position-relative" v-for="tenant in screensaverTenants.slice(0,2)"
           :key="tenant.id" @click="activeTenant = tenant; $emit('startClick')">
        <img class="img-fluid" :src="tenant.image">
        <!--        <span>{{ tenant.name }}</span>-->
      </div>

    </div>
  </section>

  <section class="container wygaszacz-space-3" id="screensaver-gap-3-area">
  </section>

  <section class="container position-relative" id="screensaver-tenants-2-area">
    <div class="row d-flex justify-content-between">
      <div class="col-auto wygaszacz-box position-relative" v-for="tenant in screensaverTenants.slice(2,5)"
           :key="tenant.id" @click="activeTenant = tenant; $emit('startClick')">
        <img class="img-fluid" :src="tenant.image">
        <!--        <span>{{ tenant.name }}</span>-->
      </div>
    </div>
  </section>

  <section class="container wygaszacz-space" id="screensaver-gap-4-area">
  </section>


</template>

<script>
import {useConfigState} from "@/stores/configStore";
import {mapState, mapWritableState} from "pinia";
import {useTenantsStore} from "@/stores/tenantsStore";
import {useControlsState} from "@/stores/controlsStore";
import layout from "@/const/layout";
import WheelchairIcon from "@/components/icons/WheelchairIcon";

export default {
  name: "ScreensaverArea",
  components: {WheelchairIcon},
  emits: ['startClick'],
  computed: {
    ...mapState(useTenantsStore, ['screensaverTenants']),
    ...mapWritableState(useTenantsStore, ['activeTenant']),
    ...mapWritableState(useControlsState, ['layout']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        browse: configStore.getTranslation('browse', 'screensaver'),
        stores: configStore.getTranslation('stores', 'screensaver'),
        changeLayout: configStore.getTranslation('changeLayout', 'footer'),
      }
    },
    isAlternativeState: function () {
      return this.layout === layout.ALTERNATIVE
    }
  },
  methods: {
    changeLayout: function () {
      this.layout = this.layout === layout.NORMAL ? layout.ALTERNATIVE : layout.NORMAL;
      if (this.layout === layout.NORMAL) {
        document.body.classList.remove('alternative-layout')
      } else {
        document.body.classList.add('alternative-layout')
      }
    }
  }
}
</script>

<style scoped>
.wygaszacz-space {
  height: 20rem;
}

.wygaszacz-button-left, .wygaszacz-button-right {
  width: 100%;
}

.change-layout {
  font-size: 5rem;
  color: white;
  text-decoration: none;
}

.wygaszacz-box {
  padding: 5rem;
}

a {
  text-decoration: none;
}
</style>
