<template>
  <section class="container subheader pt-4" id="search-keyboard-header-area" @click="showLocationActive=true">
    <div class="row mt-5">
      <div class="col-9 subheader-button py-5 text-center">
        <span>{{ translation.findMe }}</span>
      </div>
      <div class="col-3"></div>
    </div>
  </section>

  <section class="container position-relative middle " id="search-keyboard-content-area">
    <div class="row">
      <div class="col-9 d-flex flex-column">
        <div class="row">
          <div class="col-12 middle-input">
                    <span class="input px-5">
                      <template v-if="input === null">
                      {{ translation.placeholder }}
                        </template>
                      <template v-else>
                        {{ input }}
                      </template>
                    </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12" :class="keyboardClass"></div>
        </div>


      </div>
      <MenuControls/>
    </div>

  </section>
</template>

<script>
import MenuControls from "@/components/Controls/MenuControls";
import Keyboard from "simple-keyboard";
import {mapState, mapWritableState} from "pinia/dist/pinia";
import {useTenantsStore} from "@/stores/tenantsStore";
import {useConfigState} from "@/stores/configStore";
import {useControlsState} from "@/stores/controlsStore";

export default {
  name: "KeyboardSearch",
  components: {MenuControls},
  data() {
    return {
      keyboardClass: "simple-keyboard",
      input: null,
      keyboard: null
    }
  },
  computed: {
    ...mapWritableState(useControlsState, ['showLocationActive']),
    ...mapState(useTenantsStore, ['tenants', 'filters']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        findMe: configStore.getTranslation('findMe', 'keyboard_search'),
        placeholder: configStore.getTranslation('placeholder', 'keyboard_search'),
        keyboardBackspace: configStore.getTranslation('keyboardBackspace', 'keyboard_search'),
        keyboardSpace: configStore.getTranslation('keyboardSpace', 'keyboard_search'),
      }
    }
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      layout: {
        'default': [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          'q w e r t y u i o p',
          'a s d f g h j k l',
          'z x c v b n m',
          '{space}'
        ]
      },
      display: {
        '{bksp}': this.translation.keyboardBackspace,
        '{space}': " "
      },
      onChange: this.onChange,
    })
  },
  unmounted() {
    this.input = null
    const store = useTenantsStore()
    store.setSearchFilter(null)
  },
  methods: {
    onChange(input) {
      if (input === "") {
        input = null
      }
      this.input = input
      const store = useTenantsStore()
      store.setSearchFilter(input)
    }
  },
  watch: {
    filters: {
      handler() {
        const store = useTenantsStore()
        if (!store.hasFilters()) {
          this.input = null
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
@import "simple-keyboard/build/css/index.css";


</style>
