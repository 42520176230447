<template>
  <section class="container subheader py-5" id="cinema-header-area">
    <div class="row mt-5">
      <div class="col-9 cinema-text py-5 text-center positon-relative">
        <span>{{ translation.header }}</span>
        <img :src="cinemaImage" v-if="cinemaImage !== null"/>
      </div>
      <div class="col-3 d-flex flex-column right-menu position-absolute" v-if="cinemaLocation !== null"
           @click="handleGoToClick">
        <div class="row right-menu-item more-info go-to-animation">
          <div class="col-12">
            <span class="ps-5">{{ translation.goTo }}</span>
            <img src="@/assets/img/arrow-right-active.svg"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container position-relative middle mt-5" id="cinema-content-area">
    <div class="row">
      <div class="col-9 d-flex flex-column">
        <div class="row">
          <div class="col cinema-container scroll" v-if="cinemaData !== undefined">

            <div class="row mb-5" v-for="movie in cinemaData" :key="movie.id">
              <div class="col-12">
                <div class="row cinema-row">
                  <div class="cinema-left col-4">
                    <span>{{ movie.title }}</span>
                  </div>
                  <div class="cinema-right col-8">
                    <div
                        v-for="hour in movie.hours" :key="hour.hour">{{ hour.hour }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col" v-else>
            {{ translation.notFound }}
          </div>
        </div>
      </div>
      <MenuControls/>
    </div>
  </section>
</template>

<script>
import MenuControls from "@/components/Controls/MenuControls";
import moment from "moment";
import {useFeedsState} from "@/stores/feedsStore";
import {useConfigState} from "@/stores/configStore";
import {useTenantsStore} from "@/stores/tenantsStore";

export default {
  name: "CinemaControls",
  components: {MenuControls},
  data() {
    return {
      cinemaData: null,
      cinemaLocation: null,
      cinemaImage: null,
    }
  },
  computed: {
    translation: () => {
      const configStore = new useConfigState()
      return {
        header: configStore.getTranslation('header', 'cinema'),
        goTo: configStore.getTranslation('goTo', 'cinema'),
        notFound: configStore.getTranslation('notFound', 'cinema'),
      }
    }
  },
  mounted() {

    const feedsStore = new useFeedsState()
    const configStore = new useConfigState()

    const cinemaData = feedsStore.feeds[configStore.currentLanguage]?.cinema[moment().format('YYYY-MM-DD')]

    if (cinemaData !== undefined) {
      this.cinemaData = this.filterHours(cinemaData)
    }

    if (configStore?.config?.parameters?.cinemaLocation !== undefined) {
      this.cinemaLocation = configStore?.config?.parameters?.cinemaLocation
    }
    if (configStore?.config?.parameters?.cinemaImage !== undefined) {
      this.cinemaImage = configStore?.config?.parameters?.cinemaImage
    }
  },
  methods: {
    filterHours: (cinemaData) => {
      let filtered = []

      const now = moment()

      cinemaData.forEach((movie) => {
        let futureHours = []
        movie.hours.forEach((hour) => {
          const time = moment().set('hours', hour.hour.substring(0, 2)).set('minutes', hour.hour.substring(3, 5))
          if (time.isAfter(now)) {
            futureHours.push({
              hour: hour.hour.substring(0, 2) + ':' + hour.hour.substring(3, 5)
            })
          }
        })
        if (futureHours.length > 0) {
          movie.hours = futureHours
          filtered.push(movie)
        }
      })


      return filtered
    },
    handleGoToClick() {
      const tenantStore = useTenantsStore()
      const tenant = tenantStore.getTenantByLocation(this.cinemaLocation)
      if (tenant !== null) {
        tenantStore.wayfindingTenant = tenant
      }
    }
  }
}
</script>

<style scoped>
.middle .cinema-container {
  overflow-y: auto;
  width: 100%;
}

.cinema-right div {
  font-size: 3rem;
  display: inline-block;
  margin-left: 3rem;
}
</style>
