import {defineStore} from 'pinia'


export const useFeedsState = defineStore('feeds', {
    state: () => {
        return {
            feeds: {}
        }
    }
})
