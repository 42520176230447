<template>
  <div class="col-3 d-flex flex-column right-menu position-absolute">
    <div class="row right-menu-item" :class="{active: activeMenu ===menuItems.SEARCH}"
         @click="activeMenu=menuItems.SEARCH; disableWayfinding()">
      <div class="col-12 position-relative">
        <span class="ps-5">{{ translation.search }}</span>
        <SearchIcon></SearchIcon>
      </div>
    </div>
    <div class="row right-menu-item"
         v-if="features.publicTransport !== undefined && features.publicTransport===true"
         :class="{active: activeMenu ===menuItems.PUBLIC_TRANSPORT}"
         @click="activeMenu=menuItems.PUBLIC_TRANSPORT; disableWayfinding()">
      <div class="col-12 position-relative ">
        <span class="ps-5">{{ translation.publicTransport }}</span>
        <PublicTransportIcon></PublicTransportIcon>
      </div>
    </div>
    <div class="row right-menu-item" :class="{active: activeMenu ===menuItems.SPECIAL_OFFERS_AND_NEWS}"
         @click="activeMenu=menuItems.SPECIAL_OFFERS_AND_NEWS; disableWayfinding()">
      <div class="col-12 position-relative">
        <span class="ps-5">{{ translation.newsAndOffers }}</span>
        <SpecialOffersAndNewsIcon></SpecialOffersAndNewsIcon>
      </div>
    </div>
    <div class="row right-menu-item"
         v-if="features.cinema !== undefined && features.cinema===true"
         :class="{active: activeMenu ===menuItems.CINEMA}"
         @click="activeMenu=menuItems.CINEMA; disableWayfinding()">
      <div class="col-12 position-relative">
        <span class="ps-5">{{ translation.cinema }}</span>
        <CinemaIcon></CinemaIcon>
      </div>
    </div>
    <div class="row right-menu-item active" v-if="isAlternativeState"
         @click="changeLayout()">
      <div class="col-12 position-relative">
        <span class="ps-5">{{ translation.changeLayout }}</span>
        <WheelchairIcon></WheelchairIcon>
      </div>
    </div>
  </div>

</template>

<script>

import {mapWritableState} from 'pinia'
import {useControlsState} from "@/stores/controlsStore";
import menuItems from "@/const/menuItems";
import {useConfigState} from "@/stores/configStore";
import SearchIcon from "@/components/icons/SearchIcon";
import PublicTransportIcon from "@/components/icons/PublicTransportIcon";
import SpecialOffersAndNewsIcon from "@/components/icons/SpecialOffersAndNewsIcon";
import CinemaIcon from "@/components/icons/CinemaIcon";
import layout from "@/const/layout";
import WheelchairIcon from "@/components/icons/WheelchairIcon";
import {useTenantsStore} from "@/stores/tenantsStore";
import {mapState} from "pinia/dist/pinia";

export default {
  name: "MenuControls",
  components: {WheelchairIcon, CinemaIcon, SpecialOffersAndNewsIcon, PublicTransportIcon, SearchIcon},
  created() {
    this.menuItems = menuItems
  },
  computed: {
    ...mapWritableState(useControlsState, ['activeMenu', 'layout', 'wayfindingActive']),
    ...mapWritableState(useTenantsStore, ['wayfindingTenant']),
    ...mapState(useConfigState, ['features']),

    translation: () => {
      const configStore = new useConfigState()
      return {
        search: configStore.getTranslation('search', 'menu'),
        newsAndOffers: configStore.getTranslation('newsAndOffers', 'menu'),
        publicTransport: configStore.getTranslation('publicTransport', 'menu'),
        cinema: configStore.getTranslation('cinema', 'menu'),
        changeLayout: configStore.getTranslation('changeLayout', 'footer'),
      }
    },
    isAlternativeState: function () {
      return this.layout === layout.ALTERNATIVE
    }
  },
  methods: {
    changeLayout: function () {
      this.layout = this.layout === layout.NORMAL ? layout.ALTERNATIVE : layout.NORMAL;
      if (this.layout === layout.NORMAL) {
        document.body.classList.remove('alternative-layout')
      } else {
        document.body.classList.add('alternative-layout')
      }
    },
    disableWayfinding: function () {
      this.wayfindingTenant = null
      this.wayfindingActive = false
    }
  }
}
</script>

<style scoped>


.right-menu-item.active svg {
  animation: shake 0.82s cubic-bezier(.15, .02, .12, .56) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
