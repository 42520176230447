const SEARCH = 'SEARCH'
const FOOD = 'FOOD'
const PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT'
const SPECIAL_OFFERS_AND_NEWS = 'SPECIAL_OFFERS_AND_NEWS'
const CINEMA = 'CINEMA'

export default {
    SEARCH: SEARCH,
    FOOD: FOOD,
    PUBLIC_TRANSPORT: PUBLIC_TRANSPORT,
    SPECIAL_OFFERS_AND_NEWS: SPECIAL_OFFERS_AND_NEWS,
    CINEMA: CINEMA
}
