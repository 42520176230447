<template>
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg"
       :class="{'force-black':forceBlack}">
    <path
        d="M19.8535 12.4219C22.1403 12.4219 23.9941 10.5681 23.9941 8.28125C23.9941 5.99445 22.1403 4.14062 19.8535 4.14062C17.5667 4.14062 15.7129 5.99445 15.7129 8.28125C15.7129 10.5681 17.5667 12.4219 19.8535 12.4219Z"
        stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.8535 12.4219V27.3281H38.0723L44.6973 40.5781L49.666 38.9219" stroke="black" stroke-width="3"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M34.7388 33.9531C34.5772 36.5332 33.6644 39.0096 32.1129 41.0773C30.5614 43.1451 28.4389 44.7138 26.0069 45.5902C23.5749 46.4667 20.9396 46.6126 18.4257 46.0099C15.9119 45.4073 13.6292 44.0824 11.8589 42.1985C10.0887 40.3147 8.90812 37.9541 8.46277 35.4077C8.01741 32.8612 8.32668 30.2401 9.35248 27.8673C10.3783 25.4944 12.0758 23.4734 14.2359 22.0533C16.396 20.6332 18.9244 19.876 21.5095 19.875H33.1032"
        stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "WheelchairIcon",
  props: ['forceBlack']
}
</script>

<style scoped>
svg {
  height: 10rem;
}

svg * {
  stroke: white;
}

svg.force-black * {
  stroke: black;
}
</style>
