<template>

  <section class="container subheader py-5" id="offers-and-news-header-area">
    <div class="row mt-5">
      <div class="col-9 promocje-text py-5 text-center positon-relative">
        <span>{{ translation.header }}</span>
      </div>
      <div class="col-3 d-flex flex-column right-menu position-absolute" v-if="currentItemTenantHasLocation ===true"
           @click="handleGoToClick">
        <div class="row right-menu-item more-info go-to-animation">
          <div class="col-12">
            <span class="ps-5">{{ translation.goTo }}</span>
            <img src="@/assets/img/arrow-right-active.svg"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container position-relative middle mt-5" id="offers-and-news-content-area">
    <div class="row">
      <div class="col-9 d-flex flex-column">
        <div class="row promocje">
          <div class="col">
            <div class="row promocje-top">
              <div class="col-12">
                <div class="main-carousel" style="width: 100%">
                  <div class="carousel-cell" v-for="item in items" :key="item.id+'_'+item.type">
                    <img :src="item.image">
                  </div>
                </div>
              </div>
            </div>
            <div class="row promocje-bottom">
              <div class="col">
                <div class="promocje-logo">
                  <img v-if="currentItem.tenant !== null" :src="findTenantImage(currentItem.tenant)"/>

                </div>
                <div class="promocje-opis scroll" v-if="currentItem !== null">
                  <span>{{ currentItem.title }}</span>
                  <span v-html=" currentItem.description ">
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MenuControls/>
    </div>
  </section>

</template>

<script>
import MenuControls from "@/components/Controls/MenuControls";
import {mapState} from "pinia";
import {useNewsStore} from "@/stores/newsStore";

import Flickity from 'flickity'
import {useTenantsStore} from "@/stores/tenantsStore";
import {useConfigState} from "@/stores/configStore";
import {mapWritableState} from "pinia/dist/pinia";

export default {
  name: "SpecialOffersAndNewsControls",
  components: {MenuControls},
  data() {
    return {
      flickity: null,
      currentItem: null
    }
  },
  computed: {
    ...mapState(useNewsStore, {items: 'all'}),
    ...mapState(useTenantsStore, ['tenants']),
    ...mapWritableState(useNewsStore, ['itemToActivate']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        goTo: configStore.getTranslation('goTo', 'news_and_offers'),
        header: configStore.getTranslation('header', 'news_and_offers'),
      }
    },
    currentItemTenantHasLocation: function () {
      const tenantStore = useTenantsStore()
      const tenant = tenantStore.getTenantById(this.currentItem?.tenant?.id)
      return tenant !== null
    }
  },
  created() {

    this.initFlickity()

  }, methods: {
    initFlickity: function () {
      this.currentItem = this.items[0]

      this.$nextTick(() => {
        this.flickity = new Flickity('.main-carousel', {
          freeScroll: false,
          autoPlay: 5000,
          pauseAutoPlayOnHover: false,
          cellAlign: 'left',
          imagesLoaded: true,
          pageDots: false
        }).on('select', (index) => {
              this.currentItem = this.items[index]
            }
        )
      })

    },
    findTenantImage: function (tenant) {
      let image = null
      this.tenants.forEach(function (tenantItem) {

        if (tenantItem.id === tenant.id) {
          image = tenantItem.image
        }
      })
      return image
    },
    handleGoToClick() {

      const tenantStore = useTenantsStore()
      const tenant = tenantStore.getTenantById(this.currentItem?.tenant?.id)

      if (tenant !== null) {
        tenantStore.wayfindingTenant = tenant
      }

    }
  },
  watch: {
    items() {
      this.flickity.destroy()
      this.initFlickity()
    },
    itemToActivate(value) {

      if (value !== null) {
        const vueHandler = this
        for (let [key, item] of this.items.entries()) {
          let itemType = item.type === 'special_offer' ? 'sale' : item.type

          if (itemType === value.type && parseInt(item.id) === parseInt(value.id)) {

            vueHandler.flickity.selectCell(key)
          }
        }
      }
    }
  },
}
</script>

<style scoped>
@import "flickity/css/flickity.css";

.carousel-cell {
  transition: 0.5s;
  filter: grayscale(0) blur(0);
}

.carousel-cell:not(.is-selected) {
  transition: 0.5s;
  filter: grayscale(1) blur(0.3rem);
}

.promocje-opis {
  overflow-y: auto;
  height: 27rem;
}

</style>
