<template>

  <SearchControls v-if="activeMenu === menuItems.SEARCH"/>
  <PublicTransportControls v-if="activeMenu === menuItems.PUBLIC_TRANSPORT"/>
  <SpecialOffersAndNewsControls v-if="activeMenu === menuItems.SPECIAL_OFFERS_AND_NEWS"/>
  <CinemaControls v-if="activeMenu === menuItems.CINEMA"/>
</template>

<script>
import SearchControls from "@/components/Controls/SearchControls";
import {mapState} from 'pinia'
import {useControlsState} from "@/stores/controlsStore";
import menuItems from "@/const/menuItems";
import PublicTransportControls from "@/components/Controls/PublicTransportControls";
import SpecialOffersAndNewsControls from "@/components/Controls/SpecialOffersAndNewsControls";
import CinemaControls from "@/components/Controls/CinemaControls";

export default {
  name: "ControlsArea",
  components: {CinemaControls, SpecialOffersAndNewsControls, PublicTransportControls, SearchControls},
  created() {
    this.menuItems = menuItems
  },
  computed: {
    ...mapState(useControlsState, ['activeMenu'])
  }
}
</script>

<style scoped>

</style>
