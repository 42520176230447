<template>
  <section class="top position-relative d-flex align-items-end" id="maps-area">

    <div id="map" :class="{'d-none':activeMenu === menuItems.PUBLIC_TRANSPORT}">
    </div>
    <div id="visioglobe-map"></div>
    <div id="tenant-bubble" class="white-bg promo-place d-flex flex-column"
         :class="{hide:!modal.show}"
         :style="{
      top:modal.y,
      left:modal.x,
    }"
         ref="tenantModal"
    >
      <div class="row search-top">
        <div class="col-3 img" v-if="modal.tenant !== null && modal.tenant.image !== undefined">
          <img v-if="modal.tenant.image" :src="modal.tenant.image"/>
          <p v-else style="font-size: 3rem;" v-html="modal.tenant.name"></p>
        </div>
        <div class="col-auto button go-to-animation" @click="goTo(modal.tenant.locations[0])">
          <span class="action">{{ translation.goTo }}</span>
        </div>
        <div class="col-auto exit" @click="closeModal()">
          <span class="action">X</span>
        </div>
      </div>
      <div class="row" v-if="modal.tenant !== null">
        <div class="col" style="font-size: 2rem">
          <template v-for="category, num in getTenantCategories(modal.tenant)" :key="category">
            <template v-if="num !== 0">&nbsp;|&nbsp;</template>
            {{ category }}
          </template>
        </div>
      </div>
      </div>
    <div id="kiosk-location" class="jump" ref="kioskLocationInfo">{{ translation.kioskLocation }}</div>
    <div id="floors" :class="{'d-none':activeMenu === menuItems.PUBLIC_TRANSPORT}">
      <template v-for="floor in floors" :key="floor.id">
        <div :class="{'active-floor':activeFloor===floor.id}"
             @click="changeFloor(floor.id)">

          {{ floor.name }}
        </div>
      </template>
    </div>
    <template v-if="activeMenu === menuItems.PUBLIC_TRANSPORT">
      <div class="komunikacja" :style="{'background-image': 'url('+transportMap+')'}"></div>
    </template>
    <template v-else>
      <section class="top-bars">
        <div class="container position-relative">
          <div class="row">

            <template v-if="activeMenu === menuItems.SEARCH && wayfindingActive===false">
              <TenantResult v-if="activeTenant"/>
              <SearchResultsList v-else/>
            </template>
            <div class="col-3 d-flex flex-column right-menu position-absolute">
              <div class="row">
                <div
                    class="col-4 d-flex flex-column align-items-center bg-white-transparent top-margin-top"
                >
                  <div class="row " v-for="poi, key in pois" :key="poi.names" @click="handlePoiClick(poi)">
                    <div class="col top-icon"
                         :class="{last:key === pois.length -1, active:activePoi!==null && activePoi.name === poi.name}"
                         v-html="poi.svg">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </template>
  </section>

</template>

<script>
import menuItems from "@/const/menuItems";
import {mapState} from "pinia/dist/pinia";
import {useControlsState} from "@/stores/controlsStore";
import SearchResultsList from "@/components/Results/SearchResultsList";
import TenantResult from "@/components/Results/TenantResult";
import {useTenantsStore} from "@/stores/tenantsStore";
import {useConfigState} from "@/stores/configStore";

import jQuery from 'jquery'
import {mapWritableState} from "pinia";

global.jQuery = jQuery
const $ = global.jQuery
window.$ = $

export default {
  name: "MapsArea",
  components: {TenantResult, SearchResultsList},
  props: ['resetSignal'],
  created() {
    this.menuItems = menuItems
  },
  async mounted() {

    const configStore = new useConfigState()
    if (configStore.config.parameters?.pois !== undefined) {
          let pois = []
          for (let poi of configStore.config.parameters.pois) {
            // poi.svg = require('@' + poi.image)
            await fetch(poi.image).then((response) => response.text()).then((svgResponse) => {
              poi.svg = svgResponse
              pois.push(poi)
            })

          }

          this.pois = pois
        }

    // const configStore = new useConfigState()
    //
    // import('@/assets/js/jquery.wayfinding').then(async () => {
    //
    //
    //   this.floors = configStore.config.parameters.floors
    //   this.activeFloor = configStore.config.parameters.floor
    //   this.defaultFloor = configStore.config.parameters.floor
    //
    //   if (configStore.config.parameters?.pois !== undefined) {
    //     let pois = []
    //     for (let poi of configStore.config.parameters.pois) {
    //       // poi.svg = require('@' + poi.image)
    //       await fetch(poi.image).then((response) => response.text()).then((svgResponse) => {
    //         poi.svg = svgResponse
    //         pois.push(poi)
    //       })
    //
    //     }
    //
    //     this.pois = pois
    //   }
    //
    //
    //   const config = {
    //     'maps': this.floors,
    //
    //     'path': {
    //       width: 3,
    //       color: '#fff',
    //       radius: 8,
    //       speed: 8
    //     },
    //     'startpoint': configStore.config.parameters.kioskLocation,
    //     'defaultMap': this.activeFloor,
    //     'zoomToRoute': false,
    //     'floorChangeAnimationDelay': 250
    //   }
    //
    //   $('#map').wayfinding(config)
    //
    //   const vueHandler = this
    //
    //   $('#map').on('wayfinding:mapsVisible', function () {
    //     vueHandler.$nextTick(() => {
    //
    //       for (let [floor, floorTenants] of Object.entries(vueHandler.tenantsByLocation)) {
    //
    //         // eslint-disable-next-line no-unused-vars
    //         for (let [objectId, /*tenant*/] of Object.entries(floorTenants)) {
    //
    //           const object = document.querySelector("[id=" + "'" + floor + "'" + "] #" + objectId)
    //
    //           if (object !== undefined && object !== null) {
    //             object.classList.add('has-tenant')
    //           }
    //         }
    //       }
    //
    //
    //       // eslint-disable-next-line no-unused-vars
    //       $('#map svg .has-tenant').on('click', function (e, payload) {
    //         vueHandler.handleObjectClick(this.id)
    //       })
    //     })
    //
    //     const kioskLocation = document.getElementById(configStore.config.parameters.kioskLocation)
    //     kioskLocation.classList.add('current-kiosk')
    //     const kioskPosition = kioskLocation.getBoundingClientRect()
    //
    //     const kioskLocationInfo = vueHandler.$refs.kioskLocationInfo
    //
    //     kioskLocationInfo.style.top = (kioskPosition.top - 105) + 'px'
    //     kioskLocationInfo.style.left = (kioskPosition.left - 25) + 'px'
    //
    //
    //   })
    //
    //   $('#map').on('wayfinding:floorChanged', function (e, payload) {
    //     if (payload.mapId !== vueHandler.activeFloor) {
    //       vueHandler.updateFloor(payload.mapId)
    //     }
    //   })
    //
    //
    // })


    this.floors = configStore.config.parameters.floors
    this.activeFloor = configStore.config.parameters.mapSettings.building+'-'+configStore.config.parameters.floor
    this.defaultFloor = configStore.config.parameters.mapSettings.building+'-'+configStore.config.parameters.floor

    this.initializeVisioglobeMap()

  },
  data() {
    return {
      floors: [],
      activeFloor: null,
      defaultFloor: null,
      modal: {
        show: false,
        x: 0,
        y: 0,
        tenant: null,
        place: null,
        keep: false
      },
      pois: [],
      activePoi: null,
      activePoiTimeout: null,
      visioglobe: {
        essential: null,
        viewer: null,
        route: null
      }
    }
  },
  computed: {
    ...mapState(useControlsState, ['activeMenu']),
    ...mapWritableState(useControlsState, ['wayfindingActive', 'showLocationActive']),
    ...mapState(useTenantsStore, ['activeTenant', 'tenantsByLocation', 'filters']),
    ...mapWritableState(useTenantsStore, ['wayfindingTenant']),
    transportMap: () => {
      const configStore = new useConfigState()
      return configStore?.config?.parameters?.transportMapUrl
    },
    translation: () => {
      const configStore = new useConfigState()
      return {
        kioskLocation: configStore.getTranslation('kioskLocation', 'map'),
        goTo: configStore.getTranslation('goTo', 'map'),
      }
    }
  },
  methods: {
    handlePoiClick: function (poi) {

      clearTimeout(this.activePoiTimeout)

      // Array.from(document.getElementsByClassName('poi-active')).forEach((el) => el.classList.remove('poi-active'))


      if (this.activePoi?.name === poi.name) {
        for (let locationId of this.activePoi.location) {
          const place = this.visioglobe.viewer.getPlace(locationId)

          if(place){
            this.visioglobe.viewer.resetPlaceColor(place)
          }

        }
        this.activePoi = null
      } else {

        if(this.activePoi){
          for (let locationId of this.activePoi.location) {
            const place = this.visioglobe.viewer.getPlace(locationId)

            if(place){
              this.visioglobe.viewer.resetPlaceColor(place)
            }

          }
        }


        this.activePoi = poi
        const vueHandler = this

        for (let locationId of this.activePoi.location) {
          const place = this.visioglobe.viewer.getPlace(locationId)

          if(place){
            this.visioglobe.viewer.setPlaceColor(place,'#f4a503')
          }

        }

        this.activePoiTimeout = setTimeout(function () {
          for (let locationId of vueHandler.activePoi.location) {
            const place = vueHandler.visioglobe.viewer.getPlace(locationId)

            if(place){
              vueHandler.visioglobe.viewer.resetPlaceColor(place)
            }

          }
          vueHandler.activePoi = null


        }, 10000)
      }

      this.closeModal()
      this.removeActiveTenant()
      this.hideWayfinding()
      this.wayfindingActive = false
      this.hideKioskLocation()

    },
    changeFloor: function (newFloor) {
      if (newFloor !== this.activeFloor) {


        this.hideWayfinding()
        this.removeActiveTenant()
        this.hideKioskLocation()

        this.wayfindingTenant = null

        this.updateFloor(newFloor)
      }
    },
    updateFloor: function (newFloor) {

      if (newFloor !== this.activeFloor) {
        this.activeFloor = newFloor

        this.visioglobe.viewer.changeFloor(this.activeFloor, {animationDuration: 1000})

      }
    },

    goTo: function (location) {

      this.hideWayfinding()

      this.closeModal()
      this.wayfindingTenant = null
      const tenantsStore = new useTenantsStore()
      this.modal.keep = true
      tenantsStore.resetFilters()

      const vueHandler = this

      const configStore = new useConfigState()

      this.visioglobe.viewer.computeRoute({
        src: configStore.config.parameters.kioskLocation,
        dst: location.premises,
        computeNavigation: false,
        navigationParameters: {}
      })
         // eslint-disable-next-line no-unused-vars
          .then(function({request,data}) {

            // eslint-disable-next-line no-undef
            vueHandler.visioglobe.route = new visioweb.Route(vueHandler.visioglobe.viewer, data, {
              useLinks: true
            })

            const legs = Object.values(data.legs)
            vueHandler.updateFloor(legs[0].dataset)
            if(legs.length>1) {

              let timeout = 3000
              for (let currentLeg = 1; currentLeg < legs.length; currentLeg++) {

                setTimeout(() => {

                  vueHandler.updateFloor(legs[currentLeg].dataset)
                }, timeout * currentLeg)
              }
            }

          })
          .catch(function(e) {
            console.log(e)
          });





      // const configStore = new useConfigState()
      //
      //
      // this.visioglobe.essential.route.setFrom({from: configStore.config.parameters.kioskLocation})
      // this.visioglobe.essential.route.setTo({to: location.premises})





    },
    removeActiveTenant: function () {
      // const active = document.getElementsByClassName('active-location')
      // for (let x = 0; x < active.length; x++) {
      //   active[x].classList.remove('active-location')
      // }


      if(this.modal.place!==null){
        this.visioglobe.viewer.resetPlaceColor(this.modal.place)
      }

      this.modal.tenant = null
      this.modal.place = null
      this.modal.show = false
    },
    handleObjectClick: function (locationId) {
      this.removeActiveTenant()

      const tenant = this.tenantsByLocation[locationId]
      const place = this.visioglobe.viewer.getPlace(locationId)

      if(tenant!==undefined && !(place === undefined  || place===null || place===false)){
        this.visioglobe.viewer.setPlaceColor(place,'#f4a503')

        this.showModal(tenant, place)

      }

      //
      // tenant.locations.forEach(location => {
      //   if (location.premises === locationId) {
      //     const element = document.getElementById(location.premises)
      //     element.classList.add('active-location')
      //     this.showModal(tenant, place)
      //     return true
      //   }
      // })

    },
    showModal: function (tenant, place) {
      // this.hideWayfinding()

      this.modal.tenant = tenant
      this.modal.place = place

      let position = null

      if (typeof (place.vg.id) !== 'undefined')
      {
        position = place.vg.position;
      } else if (place.vg.poi)
      {
        position = place.options('position')
      }else{
        this.closeModal()
      }

      this.modal.show = true

      this.$nextTick(() => {
        const kioskPositionScreenPosition = this.visioglobe.viewer.convertPointToScreen({x: position.x, y: position.y})
        const modalAreaBox = document.getElementById('tenant-bubble').getBoundingClientRect()
        const modalWidth = modalAreaBox.width
        const modalHeight = modalAreaBox.height

        let centerX = kioskPositionScreenPosition.left - modalWidth / 2
        let centerY = kioskPositionScreenPosition.top

        const mapaAreaBox = document.getElementById('maps-area').getBoundingClientRect()

        if (centerX < mapaAreaBox.left) {
          centerX = mapaAreaBox.left + 5
        } else if (centerX + modalWidth > mapaAreaBox.right) {
          centerX = mapaAreaBox.right - 5 - modalWidth
        }

        if (centerY > (mapaAreaBox.bottom - mapaAreaBox.top) / 2) {
          centerY = centerY - (modalHeight + 25)
        } else {
          centerY = centerY + (+25)
        }

        this.modal.x = centerX + 'px'
        this.modal.y = centerY + 'px'

        const tenantsStore = new useTenantsStore()
        if (tenantsStore.hasFilters()) {
          this.modal.keep = true
          tenantsStore.resetFilters()
        } else {
          this.modal.keep = false
        }
      })

    },
    closeModal: function () {
      this.modal.show = false
      this.modal.tenant = null
      this.removeActiveTenant()
    },
    // resetPath() {
    //   $('path[class^=directionPath]').remove()
    // },
    // resetWayfindingClass() {
    //   $('#Rooms *.wayfindingRoom').removeClass('wayfindingRoom')
    // },
    hideWayfinding() {
      // this.resetPath()
      // this.resetWayfindingClass()
      if(this.visioglobe.route !== null){
        this.visioglobe.route.remove()
        this.visioglobe.route = null
      }
    },
    resetToDefaultFloor: function () {
      this.changeFloor(this.defaultFloor)
    },
    showKioskLocation() {
      this.resetToDefaultFloor()
      this.closeModal()
      this.hideWayfinding()

      const configStore = new useConfigState()

      let kioskPlace = this.visioglobe.viewer.getPlace(configStore.config.parameters.kioskLocation)

      if(kioskPlace===false){
        const pois = this.visioglobe.viewer.getPOI(configStore.config.parameters.kioskLocation)
        if(pois.length > 0){
          kioskPlace = pois[0]
        }
      }

    if(kioskPlace){
      let kioskPosition = null

      if (typeof (kioskPlace.vg.id) !== 'undefined')
      {
        kioskPosition = kioskPlace.vg.position;
      } else if (kioskPlace.vg.poi)
      {
        kioskPosition = kioskPlace.options('position')
      }

      if(kioskPosition!==null){

        const kioskPositionScreenPosition = this.visioglobe.viewer.convertPointToScreen({x: kioskPosition.x, y: kioskPosition.y})

        const kioskLocationInfo = this.$refs.kioskLocationInfo

        kioskLocationInfo.style.left = (kioskPositionScreenPosition.left-75) + 'px'
        kioskLocationInfo.style.top = (kioskPositionScreenPosition.top-225) + 'px'

      }


      this.$refs.kioskLocationInfo.style.display = 'flex'
      const vueHandler = this
      setTimeout(function () {
        vueHandler.hideKioskLocation()
      }, 5000)
    }

    },
    hideKioskLocation() {
      this.$refs.kioskLocationInfo.style.display = 'none'
    },
    getCategoryById: function (categoryId) {
      const store = new useTenantsStore()

      let category = null
      for (const cat of store.categories) {
        if (cat.id == categoryId) {
          category = cat.name
        }
      }
      return category

    },
    getTenantCategories: function (tenant) {

      let categories = []
      if (tenant.categories !== null && tenant.categories !== undefined) {
        for (const categoryId of tenant.categories) {

          const category = this.getCategoryById(categoryId)

          if (category !== null) {
            categories.push(category)
          }
        }
      }

      return categories
    },
      initializeVisioglobeMap(){

      const vueHandler = this

      // eslint-disable-next-line no-undef
      const viewer = new visioweb.Mapviewer()
      this.visioglobe.viewer = viewer
      window.viewer = this.visioglobe.viewer
      window.mapviewer = this.visioglobe.viewer

// eslint-disable-next-line no-undef
      this.visioglobe.viewer.initialize(document.getElementById('visioglobe-map'),{
        path: '/dist/map/descriptor.json',
        onObjectMouseUp: function (event, place){
          vueHandler.handleObjectClick(place.vg.id)
        }
      }).then(() => {

        const configStore = new useConfigState()


        this.visioglobe.viewer.start()

        /**
         * Set camera position and limitations
         */
        const camera = this.visioglobe.viewer.camera
        camera.maxRadius = configStore.config.parameters.mapSettings.zoomSettings.max
        camera.minRadius = configStore.config.parameters.mapSettings.zoomSettings.min
        camera.rotationManipulatorEnabled = configStore.config.parameters.mapSettings.rotation
        camera.pitchManipulatorEnabled = configStore.config.parameters.mapSettings.pitch
        camera.zoomManipulatorEnabled = configStore.config.parameters.mapSettings.zoom
        camera.panManipulatorEnabled = configStore.config.parameters.mapSettings.pan

        const newPos = camera.position
        newPos.radius = configStore.config.parameters.mapSettings.zoomSettings.default

        if(configStore.config.parameters.mapSettings.zoomSettings?.x){
          newPos.x = configStore.config.parameters.mapSettings.zoomSettings?.x
        }
        if(configStore.config.parameters.mapSettings.zoomSettings?.y){
          newPos.y = configStore.config.parameters.mapSettings.zoomSettings?.y
        }

        camera.goTo(newPos)

        this.visioglobe.viewer.changeFloor(this.defaultFloor, {animationDuration: 0})

        const tenantStore = new useTenantsStore()

        for (let tenant of tenantStore.tenants) {
          if(tenant.locations){
            for (let location of tenant.locations) {
              this.visioglobe.viewer.setPlaceIcon(location.premises, {
                url: window.location.href+tenant.image,
                visibilityRampStartInvisible: 1000,
                visibilityRampFullyInvisible: 1000,
                clickable: false,
                keepAspectRatio: true
              })

            }
          }

        }

      })

    }
  },
  watch: {
    wayfindingTenant: function (newValue, oldValue) {

      if (oldValue === null && newValue !== null) {
        this.closeModal()
        this.hideWayfinding()

        /**
         * Start wayfinding
         */
        const location = this?.wayfindingTenant?.locations !== undefined && this.wayfindingTenant.locations.length > 0 ? this.wayfindingTenant.locations[0] : null
        this.goTo(location)
      } else if (newValue === null) {

        this.removeActiveTenant()
      }
    }
    ,
    filters: {
      handler() {
        if (!this.modal.keep) {
          this.closeModal()
          this.removeActiveTenant()
          this.hideWayfinding()
          this.wayfindingActive = false
          this.resetToDefaultFloor()
          this.hideKioskLocation()
        }

        this.modal.keep = false

      }
      ,
      deep: true
    }
    ,
    activeMenu: function () {
      this.closeModal()
      this.removeActiveTenant()
      this.hideWayfinding()
      this.wayfindingActive = false
      this.resetToDefaultFloor()
      this.hideKioskLocation()
    },
    resetSignal: function (val) {
      if (val === true) {
        this.closeModal()
        this.removeActiveTenant()
        this.hideWayfinding()
        this.wayfindingActive = false
        this.resetToDefaultFloor()
        this.hideKioskLocation()
      }
    },
    showLocationActive: function (val) {
      if (val === true) {
        this.showKioskLocation()
        this.showLocationActive = false
      }
    }
  }
}
</script>

<style scoped>
.komunikacja {
  width: 100%;
  height: 100%;
  background-size: cover;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  height: 151rem;
  padding: 5rem 10rem;
  text-align: center;
  width: 100%;
}


#floors {
  position: absolute;
  bottom: 0;
  left: 12rem;
  z-index: 1000;
  font-size: 5rem;
  background-color: white;
  padding: 2rem;
  display: flex;
}

#floors > div {
  padding: 0 5rem;
  color: black;
  width: 15rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#floors > div.active-floor {
  background-color: #2a6075;
  color: white;
}

#tenant-bubble {
  position: absolute;
  height: 30rem;
  width: 100rem;
  justify-content: center;
  box-shadow: #8A8A8A 0px 0px 5em;
}

#tenant-bubble {
  display: block;
}

#tenant-bubble.hide {
  display: none !important;
}

#maps-area {
  background-color: #f4f4f4;
}

#kiosk-location {
  position: absolute;
  top: 100px;
  left: 100px;
  background-color: #559c7e;
  width: 15rem;
  height: 15rem;
  color: white;
  display: none;
  padding: 2rem;
  font-size: 3rem;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

#kiosk-location:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -7.5rem;
  width: 0;
  height: 0;
  border-top: solid 7.5rem #559c7e;
  border-left: solid 7.5rem transparent;
  border-right: solid 7.5rem transparent;
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, -10%, 0);
  }
  100% {
    transform: translate3d(0, -30%, 0);
  }
}

.jump {
  transform-origin: 50% 50%;
  animation: jump .5s linear alternate infinite;
}

#visioglobe-map{
  position: absolute;
  top: 0;
  left: 0;
  height: 151rem;
  /*padding: 5rem 10rem;*/
  text-align: center;
  width: 100%;
  /*background-color: red;*/
}

</style>
<style>


#map div {
  display: inline-block;
  height: 100%;
  width: 100%;
}

#map svg #Rooms *.has-tenant {
  fill: #a9a9a9 !important;
}

#map svg #Rooms *.has-tenant.active-location {
  fill: #4C4C4C !important;
}


#map svg #Rooms * {
  pointer-events: all !important;
  fill: #e2e2e2 !important;
  stroke: #4C4C4C !important;
}

#map svg #corridor {
  fill: #f4f4f4 !important;
}

#map svg #wayfinding > * {
  stroke: #F4A503 !important
}

#map svg #Rooms *.has-tenant.wayfindingRoom {
  fill: #F4A503 !important
}

#map svg *[class^="directionPath"] {
  stroke: #F4A503 !important;
}

#map svg #Rooms *[id^="kiosk"], #map svg #Rooms *[id^="kiosk"] * {
  stroke: none !important;
}

#map svg .poi-active {
  transform-origin: 50% 50%;
  transform-box: fill-box;
  animation-duration: 2s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    transform: scale(100%);
  }

  50% {
    transform: scale(200%);
  }

  100% {
    transform: scale(100%);
  }
}

</style>
