import {defineStore} from 'pinia'


export const useConfigState = defineStore('config', {
    state: () => {
        return {
            config: null,
            currentLanguage: null,
            languages: [],
            translation: null,
            features: {}
        }
    },
    getters: {
        getTranslation: (state) => {
            return (phrase, group) => {
                if (state.translation[group] !== undefined && state.translation[group][phrase] !== undefined) {
                    return state.translation[group][phrase]
                }

                return group + '.' + phrase
            }
        }
    },
    actions: {
        updateTranslation(lang) {
            if (this.config.parameters.trans[lang] !== undefined) {
                this.translation = this.config.parameters.trans[lang]
            }

        }
    }
})
