<template>
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.69531 14.9062H44.3047" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.69531 24.8438H44.3047" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M44.3047 39.75H8.69536V13.25C8.69262 12.5968 8.81927 11.9494 9.068 11.3453C9.31673 10.7413 9.68262 10.1924 10.1446 9.7305C10.6065 9.26856 11.1553 8.90267 11.7594 8.65394C12.3635 8.4052 13.0108 8.27855 13.6641 8.28129H39.336C39.9893 8.27855 40.6366 8.4052 41.2407 8.65394C41.8447 8.90267 42.3936 9.26856 42.8555 9.7305C43.3175 10.1924 43.6834 10.7413 43.9321 11.3453C44.1808 11.9494 44.3075 12.5968 44.3047 13.25V39.75Z"
        stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M44.3047 39.75V44.7188C44.3047 45.158 44.1302 45.5793 43.8196 45.8899C43.509 46.2005 43.0877 46.375 42.6484 46.375H37.6797C37.2404 46.375 36.8191 46.2005 36.5085 45.8899C36.1979 45.5793 36.0234 45.158 36.0234 44.7188V39.75"
        stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M16.9766 39.75V44.7188C16.9766 45.158 16.8021 45.5793 16.4915 45.8899C16.1809 46.2005 15.7596 46.375 15.3203 46.375H10.3516C9.9123 46.375 9.49102 46.2005 9.18042 45.8899C8.86981 45.5793 8.69531 45.158 8.69531 44.7188V39.75"
        stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="to-fill"
          d="M17.3906 34.7812C18.7627 34.7812 19.875 33.669 19.875 32.2969C19.875 30.9248 18.7627 29.8125 17.3906 29.8125C16.0185 29.8125 14.9062 30.9248 14.9062 32.2969C14.9062 33.669 16.0185 34.7812 17.3906 34.7812Z"
          fill="black"/>
    <path class="to-fill"
          d="M35.6094 34.7812C36.9815 34.7812 38.0938 33.669 38.0938 32.2969C38.0938 30.9248 36.9815 29.8125 35.6094 29.8125C34.2373 29.8125 33.125 30.9248 33.125 32.2969C33.125 33.669 34.2373 34.7812 35.6094 34.7812Z"
          fill="black"/>
  </svg>


</template>

<script>
export default {
  name: "PublicTransportIcon"
}
</script>

<style scoped>
svg {
  height: 7rem;
}

.active svg * {
  stroke: white;
}

.active svg .to-fill {
  fill: white;
}
</style>
