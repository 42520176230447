<template>
  <KeyboardSearch v-if="mode==='keyboard'"/>
  <CategoriesSearch v-if="mode==='categories'"/>

  <section class="container position-relative middle " id="search-mode-area">
    <div class="row">
      <div class="col-12 mb-5 d-flex flex-column">
        <div class="row">
          <div class="col letter-search"><span>{{ translation.search }}:</span></div>
          <div class="col-4 letter-category me-5" :class="{active:this.mode==='keyboard'}"
               @click="mode='keyboard';activeTenant=null">
            <span>{{ translation.byName }}</span>
          </div>
          <div class="col-4 letter-category" :class="{active:this.mode==='categories'}"
               @click="mode='categories';activeTenant=null">
            <span>{{ translation.byCategory }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import KeyboardSearch from "@/components/Controls/Search/KeyboardSearch";
import CategoriesSearch from "@/components/Controls/Search/CategoriesSearch";
import {useConfigState} from "@/stores/configStore";
import {mapWritableState} from "pinia";
import {useTenantsStore} from "@/stores/tenantsStore";

export default {
  name: "SearchControls",
  components: {CategoriesSearch, KeyboardSearch},
  data() {
    return {
      mode: 'keyboard'
    }
  },
  computed: {
    ...mapWritableState(useTenantsStore, ['activeTenant']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        search: configStore.getTranslation('search', 'search_controls'),
        byCategory: configStore.getTranslation('byCategory', 'search_controls'),
        byName: configStore.getTranslation('byName', 'search_controls'),
      }
    }
  }, unmounted() {
    this.activeTenant = null
  }
}
</script>

<style scoped>
/**
Rzeźba
 */
#search-mode-area {
  width: 67.2%;
  margin-left: 11rem;
  margin-right: 0;
}
</style>
