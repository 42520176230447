<template>
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M25.4025 5.36192L8.69512 8.69512L5.36192 25.4025C5.30969 25.6693 5.32391 25.9448 5.40335 26.2048C5.48278 26.4647 5.62498 26.7012 5.81739 26.8932L27.4314 48.5072C27.5838 48.6631 27.7657 48.787 27.9666 48.8715C28.1675 48.9561 28.3832 48.9996 28.6012 48.9996C28.8191 48.9996 29.0349 48.9561 29.2357 48.8715C29.4366 48.787 29.6186 48.6631 29.7709 48.5072L48.5072 29.7709C48.6631 29.6186 48.787 29.4366 48.8715 29.2357C48.9561 29.0349 48.9996 28.8191 48.9996 28.6012C48.9996 28.3832 48.9561 28.1675 48.8715 27.9666C48.787 27.7657 48.6631 27.5838 48.5072 27.4314L26.8932 5.81739C26.7012 5.62498 26.4647 5.48278 26.2048 5.40335C25.9448 5.32391 25.6693 5.30969 25.4025 5.36192V5.36192Z"
        stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="to-fill"
          d="M17.3906 19.875C18.7627 19.875 19.875 18.7627 19.875 17.3906C19.875 16.0185 18.7627 14.9062 17.3906 14.9062C16.0185 14.9062 14.9062 16.0185 14.9062 17.3906C14.9062 18.7627 16.0185 19.875 17.3906 19.875Z"
          fill="black"/>
  </svg>

</template>

<script>
export default {
  name: "SpecialOffersAndNewsIcon"
}
</script>

<style scoped>
svg {
  height: 7rem;
}

.active svg * {
  stroke: white;
}

.active svg .to-fill {
  fill: white;
}
</style>
