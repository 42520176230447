<template>
  <section class="container subheader py-5 ps-0" id="search-categories-header-area">
    <div class="row mt-5">
      <div class="col-12">
        <div class="d-flex">
          <div class="category-alphabet col">
            <a href="#" v-for="letter in letters"
               :key="letter" :class="{active: activeLetter===letter}"
               @click="activeLetter=(letter !== activeLetter ? letter : null); wayfindingTenant=null; wayfindingActive=false">
              <span class="letter"
              > {{ letter }} </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container position-relative middle mt-5" id="search-categories-content-area">
    <div class="row">
      <div class="col-9 category d-flex flex-column mb-5">
        <div class="row category-new">
          <div class="col-6 pe-5">
            <div class="row category-border">
              <div class="col-12 category-item" :class="{ active: category.id === this.activeCategory }"
                   v-for="category in slicedCategories.slice(0, categories.length/2)"
                   :key="category"
                   @click="activeCategory=(activeCategory!==category.id ? category.id : null); wayfindingTenant=null; wayfindingActive=false"
              >
                <span>{{ category.name }}</span>
              </div>
            </div>
          </div>
          <div class="col-6 pe-5">
            <div class="row category-border">
              <div class="col-12 category-item" :class="{ active: category.id === this.activeCategory }"
                   v-for="category in slicedCategories.slice(categories.length/2)"
                   :key="category"
                   @click="activeCategory=(activeCategory!==category.id ? category.id : null); wayfindingTenant=null; wayfindingActive=false"
              >
                <span>{{ category.name }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <MenuControls/>
    </div>
  </section>

</template>

<script>
import MenuControls from "@/components/Controls/MenuControls";
import {useTenantsStore} from "@/stores/tenantsStore";
import {getTenantLetter} from "@/utils/getTenantLetter";
import {mapState, mapWritableState} from "pinia";
import {useControlsState} from "@/stores/controlsStore";

export default {
  name: "CategoriesSearch",
  components: {MenuControls},
  data() {
    return {
      activeCategory: null,
      activeLetter: null
    }
  }, computed: {
    ...mapState(useTenantsStore, ['tenants', 'categories', 'filters']),
    ...mapWritableState(useTenantsStore, ['wayfindingTenant']),
    ...mapWritableState(useControlsState, ['wayfindingActive']),
    slicedCategories(){
      return this.categories.slice(0,10)
    },
    letters() {

      let letters = []

      this.tenants.forEach(tenant => {
        let letter = getTenantLetter(tenant)

        if (!letters.includes(letter)) {
          letters.push(letter)
        }
      })

      return letters
    }
  },
  watch: {
    activeCategory(value) {
      const store = useTenantsStore()
      store.setCategoryFilter(value)
    },
    activeLetter(value) {
      const store = useTenantsStore()
      store.setLetterFilter(value)
    },
    filters: {
      handler() {
        const store = useTenantsStore()
        if (!store.hasFilters()) {
          this.activeLetter = null
          this.activeCategory = null
        }
      },
      deep: true
    }
  },
  unmounted() {
    const store = useTenantsStore()
    store.setCategoryFilter(null)
    store.setLetterFilter(null)
  }
}
</script>

<style scoped>
/*.category-item {*/
/*  flex: 0 0 auto;*/
/*  width: 45%;*/
/*}*/

/*.category-item:nth-child(2n) {*/
/*  margin-left: 10%;*/
/*}*/

/*.category .row {*/
/*  padding-bottom: 15px;*/
/*}*/

/*.subheader .category-alphabet {*/
/*  padding: 0 5rem;*/
/*}*/

/*.letter {*/
/*  margin: 0 !important;*/
/*  padding: 3rem 0;*/
/*  flex: 1;*/
/*}*/

/*.letter.active {*/
/*  color: white;*/
/*  border: 0;*/
/*}*/

.subheader .category-alphabet {
  padding-right: 5.4rem;
}

.subheader .category-alphabet .letter {
  margin-right: 0;
}

.subheader .category-alphabet a {
  flex: 1;
}

.subheader .category-alphabet a.active {
  border-radius: 2.5rem;
  border-width: 0 !important;
}

/*.subheader .category-alphabet a:last-child {*/
/*  margin-right: 0;*/
/*}*/
</style>
