<template>
  <section class="container bottom" id="ads-header-area">
    <div class="row margin-bottom-10">
      <div class="col-12 mb-4">
        <span class="aktualnosci">
<!--          Aktualnosci-->
        </span>
      </div>
    </div>
  </section>
  <section class="container-fluid " id="ads-content-area">
    <div class="banner-carousel">
      <img :src="ad.image" class="carousel-cell" v-for="ad in advertisements" :key="ad"
           @click.prevent="handleAdClick(ad)"/>
    </div>
  </section>
</template>

<script>

import {useConfigState} from "@/stores/configStore";
import {useFeedsState} from "@/stores/feedsStore";
import Flickity from "flickity";
import {mapState} from "pinia";
import {useTenantsStore} from "@/stores/tenantsStore";

export default {
  name: "AdsArea",
  emits: ['tenantAdClick', 'newsAndSaleAdClick'],
  data() {
    return {
      flickity: null
    }
  },
  computed: {
    advertisements: () => {

      const configStore = new useConfigState()
      const feedStore = new useFeedsState()

      const adsFeed = feedStore?.feeds[configStore.currentLanguage]?.ads?.adsBottom?.ads

      if (adsFeed !== undefined) {
        return adsFeed
      } else {
        return []
      }
    },
    ...mapState(useConfigState, ['currentLanguage'])
  }, mounted() {
    this.initFlickity()
  }, methods: {
    initFlickity: function () {
      this.$nextTick(() => {
        Flickity.prototype.stopPlayer = function () {
          this.player.stop();
          // always resume play after 3 seconds
          setTimeout(() => {
            this.player.play();
          }, 5000)
        };
        this.flickity = new Flickity('.banner-carousel', {
          freeScroll: false,
          autoPlay: 5000,
          pauseAutoPlayOnHover: false,
          cellAlign: 'left',
          imagesLoaded: true,
          pageDots: false,
          prevNextButtons: false
        })
      })
    },
    restartAutoplay: function () {
      this.flickity.flickity('playPlayer');
    },
    handleAdClick: function (ad) {
      if (ad.link !== undefined && ad.link !== null) {
        if (ad.link?.type === 'tenant') {
          const tenantStore = new useTenantsStore()
          const tenant = tenantStore.getTenantById(ad.link?.id)

          if (tenant !== null) {
            tenantStore.activeTenant = tenant
            this.$emit('tenantAdClick')
          }
        } else if (ad.link?.type === 'sale' || ad.link?.type === 'news') {
          this.$emit('newsAndSaleAdClick', ad.link)
        }
      }

    }
  },
  watch: {
    currentLanguage: function () {
      this.flickity.destroy()
      this.initFlickity()
    }
  }
}
</script>

<style scoped>
@import "flickity/css/flickity.css";

.carousel-cell {
  padding: 0 2rem;
}

</style>
