import {defineStore} from "pinia";

export const useNewsStore = defineStore('news', {
    state: () => {
        return {
            news: null,
            specialOffers: null,
            all: null,
            itemToActivate: null
        }
    }
})
