<template>
  <template v-if="loaded">
    <GlobalEvents @click="resetScreensaverTimout()"  @contextmenu.prevent="" @onselectstart.prevent=""/>
    <MapsArea @click="handleStart" :reset-signal="resetSignal"/>
    <ScreensaverArea v-if="screensaver" @startClick="handleStart"/>
    <template v-else>
      <GlobalEvents @click="resetScreensaverTimout()" @contextmenu.prevent="" @onselectstart.prevent=""/>

      <ControlsArea/>
      <AdsArea @tenantAdClick="handleTenantAdClick()" @newsAndSaleAdClick="handleNewsAndSaleAdClick"/>
    </template>
    <FooterArea @languageChanged="handleLanguageChange"/>

  </template>
  <template v-else>
    <GlobalEvents @contextmenu.prevent="" @onselectstart.prevent=""/>
    <LoadingScreen/>
  </template>
</template>

<script>

import FooterArea from "@/components/Area/FooterArea";
import AdsArea from "@/components/Area/AdsArea";
import ControlsArea from "@/components/Area/ControlsArea";
import MapsArea from "@/components/Area/MapsArea";
import {useConfigState} from "@/stores/configStore";
import {mapWritableState} from "pinia";
import LoadingScreen from "@/components/Misc/LoadingScreen";
import {useFeedsState} from "@/stores/feedsStore";
import {useTenantsStore} from "@/stores/tenantsStore";
import latinize from "latinize";
import ScreensaverArea from "@/components/Area/ScreensaverArea";
import {GlobalEvents} from 'vue-global-events'
import {useControlsState} from "@/stores/controlsStore";
import menuItems from "@/const/menuItems";
import {useNewsStore} from "@/stores/newsStore";
import layout from "@/const/layout";

export default {
  name: 'App',
  components: {ScreensaverArea, LoadingScreen, MapsArea, ControlsArea, AdsArea, FooterArea, GlobalEvents},
  created() {

    /**
     * Load configuration and data
     */
    fetch('/dist/config.json').then(async response => {
      const data = await response.json()

      if (response.ok) {
        const vueHandler = this


        vueHandler.config = data

        // Set default configs
        vueHandler.setDefaultConfigs()

        const layout = vueHandler.config?.parameters?.theme
        if(layout){
          document.getElementById('app').classList.add(layout)
        }

        // Create list of feeds to download
        const feedLangs = Object.keys(this.config.feeds)
        let feeds = []
        let feedsData = {}
        feedLangs.forEach(lang => {
          feedsData[lang] = {}
          vueHandler.feeds[lang] = {}
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(this.config.feeds[lang])) {
            feeds.push(value)
          }
        })

        let feedsLeft = feeds.length

        /**
         * @todo Note to future self: probably should be rewritten, because is just a bunch of stackoverflow examples smacked together,
         * and I'm not sure how and why it works
         */
        Promise.all(feeds.map(f => fetch(f))).then(responses => responses.forEach(async r => {
          let feedData = await r.json()
          const re = /(?<lang>\w{2})-(?<type>\w+).json$/

          const match = re.exec(r.url)

          if (match.groups.type === 'tenants' || match.groups.type === 'food' || match.groups.type === 'services'){
            feedData = vueHandler.latinizeTenantsNames(feedData)
            feedData = vueHandler.extractTenantsFloors(feedData)
          }

          vueHandler.feeds[match.groups.lang][match.groups.type] = feedData

          if (--feedsLeft === 0) {
            vueHandler.tenants = vueHandler.feeds[this.currentLanguage].tenants
            if (vueHandler.feeds[this.currentLanguage]['food'] !== undefined) {
              vueHandler.tenants.push(...vueHandler.feeds[this.currentLanguage].food)
            }
            if (vueHandler.feeds[this.currentLanguage]['services'] !== undefined) {
              vueHandler.tenants.push(...vueHandler.feeds[this.currentLanguage].services)
            }
              vueHandler.categories = vueHandler.feeds[this.currentLanguage].category

            const newsStore = new useNewsStore()
            newsStore.news = vueHandler.feeds[this.currentLanguage].news
            newsStore.specialOffers = vueHandler.feeds[this.currentLanguage].sale
            newsStore.all = (([...newsStore.news, ...newsStore.specialOffers]).sort(() => Math.random() - 0.5))

            vueHandler.loaded = true
          }

        }))


        const screensaverTimeoutTime = vueHandler?.config?.parameters?.idleTimerLimit
        if (screensaverTimeoutTime !== undefined) {
          vueHandler.screensaverTimeoutTime = parseInt(screensaverTimeoutTime)
        }


      }

    })
  },
  data() {
    return {
      loaded: false,
      screensaver: true,
      screensaverTimeout: null,
      screensaverTimeoutTime: 60000,
      resetSignal: false,
    }
  },
  computed: {
    ...mapWritableState(useFeedsState, ['feeds']),
    ...mapWritableState(useConfigState, ['config', 'currentLanguage', 'languages', 'features']),
    ...mapWritableState(useTenantsStore, ['tenants', 'categories', 'wayfindingTenant'])

  }, methods: {
    setDefaultConfigs() {
      this.currentLanguage = this.config.parameters.lang
      this.languages = this.config.parameters.langs
      const configStore = useConfigState()
      configStore.updateTranslation(this.currentLanguage)

      const features = this.config.parameters?.availableFeatures
      if (features !== undefined) {
        this.features = features
      }

    },
    latinizeTenantsNames(tenantsFeed) {
      tenantsFeed.forEach(function (tenant, index, array) {
        array[index].normalizedName = latinize(tenant.name)
        array[index].normalizedDescription = latinize(tenant.description)
      })
      return tenantsFeed
    },
    extractTenantsFloors(tenantsFeed) {
      tenantsFeed.forEach(function (tenant, index, array) {
        let floors = [];
        if (tenant.locations !== undefined && tenant.locations !== null && tenant.locations.length > 0) {

          if (Array.isArray(tenant.locations)) {
            tenant.locations.forEach(floorData => {
              if (!floors.includes(floorData.floor)) {
                floors.push(floorData.floor)
              }

            })
          }

        }

        floors.sort()

        array[index].floors = floors
      })

      return tenantsFeed
    },
    handleStart() {

      if (this.screensaver) {
        this.screensaver = false

        this.resetScreensaverTimout()
      }

    },
    resetScreensaverTimout() {

      if (this.screensaverTimeout !== null) {
        clearTimeout(this.screensaverTimeout)
      }

      this.screensaverTimeout = setTimeout(() => {
        this.screensaver = true
        this.resetToDefaults()
      }, this.screensaverTimeoutTime)

      this.resetSignal = false
    },
    resetToDefaults() {
      this.currentLanguage = this.config.parameters.lang
      const configStore = useConfigState()
      configStore.updateTranslation(this.currentLanguage)
      this.tenants = this.feeds[this.currentLanguage].tenants
      this.categories = this.feeds[this.currentLanguage].category
      const tenantsStore = useTenantsStore()
      tenantsStore.resetFilters()
      tenantsStore.activeTenant = null
      const controlsStore = useControlsState()
      controlsStore.activeMenu = menuItems.SEARCH

      controlsStore.layout = layout.NORMAL

      document.body.classList.remove('alternative-layout')

      this.wayfindingTenant = null

      this.resetSignal = true

    },
    handleTenantAdClick() {
      const tenantsStore = useTenantsStore()
      const activeTenant = tenantsStore.activeTenant
      this.resetToDefaults()
      tenantsStore.activeTenant = activeTenant
      this.handleStart()
    },
    handleNewsAndSaleAdClick(event) {
      const newsStore = useNewsStore()

      this.resetToDefaults()
      this.handleStart()
      const controlsStore = useControlsState()
      controlsStore.activeMenu = menuItems.SPECIAL_OFFERS_AND_NEWS
      this.$nextTick(() => newsStore.itemToActivate = event)
    },
    handleLanguageChange() {
      this.resetSignal = true
      const tenantsStore = useTenantsStore()
      tenantsStore.resetFilters()
      tenantsStore.activeTenant = null
      this.$nextTick(function () {
        this.resetSignal = false
      })
    }
  }
}
</script>

<style>
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/style.css";
@import "./assets/css/fixes.css";
@import "./assets/css/scroll.css";
@import "./assets/css/horizontal.css";

* {
  touch-action: pan-y;
  user-select: none;
}


</style>
