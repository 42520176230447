<template>
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M24.2917 41.9583C34.0487 41.9583 41.9583 34.0487 41.9583 24.2917C41.9583 14.5346 34.0487 6.625 24.2917 6.625C14.5346 6.625 6.625 14.5346 6.625 24.2917C6.625 34.0487 14.5346 41.9583 24.2917 41.9583Z"
        stroke="black" stroke-width="4.41667" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M46.3758 46.3768L36.7695 36.7705" stroke="black" stroke-width="4.41667" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "SearchIcon"
}
</script>

<style scoped>
svg {
  height: 7rem;
}

.active svg * {
  stroke: white;
}
</style>
