<template>
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M44.7188 9.9375H8.28125C7.36653 9.9375 6.625 10.679 6.625 11.5938V41.4062C6.625 42.321 7.36653 43.0625 8.28125 43.0625H44.7188C45.6335 43.0625 46.375 42.321 46.375 41.4062V11.5938C46.375 10.679 45.6335 9.9375 44.7188 9.9375Z"
        stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.5 9.9375V43.0625" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.625 16.5625H46.375" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.625 36.4375H46.375" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.5625 9.9375V16.5625" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36.4375 9.9375V16.5625" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.5625 36.4375V43.0625" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36.4375 36.4375V43.0625" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "CinemaIcon"
}
</script>

<style scoped>
svg {
  height: 7rem;
}

.active svg * {
  stroke: white;
}
</style>
