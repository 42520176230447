<template>
  <section class="container subheader py-5" id="transport-header-area">
    <div class="row mt-5">
      <div class="col-9 komunikacja-text py-5 text-center positon-relative">
        <span>{{ translation.header }}</span>
      </div>
      <div class="col-3">
      </div>
    </div>
  </section>

  <section class="container position-relative middle mt-5" id="transport-content-area">
    <div class="row">
      <div class="col-9 d-flex flex-column">
        <div class="row komunikacja-container scroll">
          <div class="col">
            <div class="row mb-5">
              <div class="col-12">
                <template
                    v-if="transportData !== null && transportData[day]!== undefined && transportData[day]['timeTable'] !== undefined">
                  <template v-for="stop in transportData[day]['timeTable']" :key="stop.id">

                    <template v-for="stopData in stop['stopNumbers']" :key="stopData.id">
                      <div class="row komunikacja-row"
                           :class="{active:activeStop===stop.id+'_'+stopData.id && stopData.lines.length > 0}"
                           @click="activeStop!==stop.id+'_'+stopData.id ? activeStop=stop.id+'_'+stopData.id : activeStop=null">
                        <div class="komunikacja-left col-auto">
                          <span>{{ stopData.name }}</span>
                        </div>
                        <div class="komunikacja-right col"
                             v-if="activeStop!==stop.id+'_'+stopData.id">
                          <span v-for="line in stopData['lines']" :key="line.id">
                            {{ line.id }}
                          </span>
                        </div>

                      </div>
                      <template v-if="activeStop===stop.id+'_'+stopData.id && stopData.lines.length > 0">
                        <div class="row komunikacja-row komunikacja-row-line"
                             @click="activeStop!==stop.id+'_'+stopData.id ? activeStop=stop.id+'_'+stopData.id : activeStop=null">
                          <div class="col">
                            <div class="row komunikacja-line-row" v-for="line in stopData.lines" :key="line.id">
                              <div class="col-1 line">
                                {{ line.id }}
                              </div>
                              <div class="col-11 departures text-end">
                                <div v-for="time in getNextDepartures(line.departures)" :key="time">
                                  {{ time }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MenuControls/>
    </div>
  </section>

</template>

<script>
import MenuControls from "@/components/Controls/MenuControls";
import {mapState} from "pinia";
import {useFeedsState} from "@/stores/feedsStore";
import {useConfigState} from "@/stores/configStore";
import moment from "moment";

export default {
  name: "PublicTransportControls",
  components: {MenuControls},
  data() {
    return {
      'transportData': null,
      'activeStop': null,
      'day': moment().isoWeekday()
    }
  },
  computed: {
    ...mapState(useFeedsState, ['feeds']),
    ...mapState(useConfigState, ['currentLanguage']),
    translation: () => {
      const configStore = new useConfigState()
      return {
        header: configStore.getTranslation('header', 'transport'),
      }
    }
  },
  mounted() {
    const transportFeed = this?.feeds[this.currentLanguage]?.transport
    if (transportFeed !== undefined) {
      this.transportData = transportFeed
    }
  },
  methods: {
    getNextDepartures: function (departures, num = 12) {
      const now = moment()
      let dates = []

      departures.forEach(departure => {
        let timeDate = moment().set('hours', departure.substring(0, 2)).set('minutes', departure.substring(3, 5)).set('second', 0)
        if (timeDate.isBefore(now)) {
          timeDate.add(1, "day")
        }
        dates.push(timeDate)

      })

      dates = dates.sort((a, b) => a.valueOf() - b.valueOf())

      let nextDepartures = []

      dates.slice(0, num).forEach(date => nextDepartures.push(date.format('HH:mm')))

      return nextDepartures
    }
  }
}
</script>

<style scoped>
.komunikacja-line-row {
  font-size: 3rem;
  padding: 0.5rem;
}

.komunikacja-line-row .departures span {
  padding: 0 1rem;
}

.komunikacja-line-row .line {
  font-weight: 600;
}

.middle .komunikacja-container {
  overflow-y: auto;
}

.middle .komunikacja-row .komunikacja-right span {
  padding-left: 1rem;
}

.middle .komunikacja-row {
  min-height: 18.2rem;
  margin-right: 2rem;
  height: auto;
}

.komunikacja-row.active {
  border: none;
  padding: 0 1rem;
  min-height: 8rem;
  justify-content: start;
}

.middle .komunikacja-row-line {
  border: 0.3rem solid #2a6075;
}

.departures div {
  display: inline-block;
  margin-left: 2rem;
}

</style>
